import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ReactPlayer from "react-player";
import { useTranslation } from 'react-i18next';

const VideoUpload = ({videoReadyCallback}) => {
  const [video, setVideo] = useState(null);
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    // Only keep the first file and discard the others
    const videoFile = acceptedFiles[0];
    if (videoFile) {
      setVideo(Object.assign(videoFile, { preview: URL.createObjectURL(videoFile) }));
    }
  }, []);

  useEffect(() => {
    if (videoReadyCallback && video) {
      videoReadyCallback(video)
    }
  }, [video])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/mp4",
    multiple: false, // Ensure only one file can be uploaded
  });

  return (
    <div style={styles.container}>
      <div
        {...getRootProps()}
        style={isDragActive ? styles.dragActive : styles.dropzone}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{t("video_upload.drag_active_prompt")}</p>
        ) : (
          <p>{t("video_upload.drag_and_drop_prompt")}</p>
        )}
      </div>

    </div>
  );
};

const styles = {
  container: {
    width: "500px",
    margin: "auto",
  },
  dropzone: {
    border: "2px dashed #007bff",
    borderRadius: "10px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  },
  dragActive: {
    border: "2px solid #007bff",
    borderRadius: "10px",
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#f0f8ff",
  },
  playerWrapper: {
    marginTop: "20px",
    position: "relative",
    paddingTop: "10px", // 16:9 aspect ratio
  },
};

export default VideoUpload;
