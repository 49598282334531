import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import VideoUpload from '../components/VideoUpload.js';
import './ClassForm.css';
import { useParams, useNavigate } from "react-router-dom";
import { auth } from '../firebase';

import '../components/backButton.css';
import '../components/videoView.css';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

export default function CreateLesson() {
  const params = useParams();
  const [lessonName, setLessonName] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [videoData, setVideoData] = useState(null);
  const [lessonDuration, setLessonDuration] = useState(null);
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [isPublicLesson, setIsPublicLesson] = useState(false); // State for advanced mode
  const [lessonError, setLessonError] = useState(null);
  const [ref, setRef] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [uploading, setUploading] = useState(false); // Track upload state

  const handleReady = () => {
    console.log('Video is ready to play!');
    setVideoLoaded(true);
    setLessonError(null);
  };

  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)
      } else {

      }
    })
  })

  useEffect(() => {
    setVideoLoaded(false)
  }, [videoLink])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!lessonDuration) {
      setLessonError(t("create_lesson_page.video_didnt_load"))
      return
    }
    let requestUploadLink = false
    if (videoData) {
      requestUploadLink = true;
    } else if (videoLink) {
      requestUploadLink = false;
    } else {
      console.log("Not creating lesson - no data delivered")
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_PATH + "/api/class/" + params.class_id + "/lesson", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'username': userEmail,
              'userid': userToken,
          },
          body: JSON.stringify({
            lessonName: lessonName,
            public: isPublicLesson,
            lessonDuration: lessonDuration,
            videoLink: requestUploadLink ? null : videoLink,
            requestUploadLink: requestUploadLink
        })
      })
      const responseJson = await response.json()
      if (!response.ok) {
        setLessonError(responseJson.error)
      } else {
        if (responseJson.url) {
          setUploading(true);
          console.log(responseJson)
          console.log("videoData.type", videoData.type)
          const uploadResponse = await fetch(responseJson.url, {
              method: 'PUT',
              headers: {
                'Content-Type': videoData.type,  // Set the correct content type
              },
              body: videoData,  // The actual video file
          })
          console.log("sent")
          console.log(uploadResponse)
        }

        setLessonName('');
        setVideoLink('');
        setVideoData(null);
        setUsers([]);
        setAdmins([]);

        navigate("..");
      }
    } catch(rejected) {
      console.log(rejected);
    } finally {
      setUploading(false);
    };
  };

  const handleGoBack = () => {
    navigate("..")
  };

  const handleError = () => {
    console.log("Cannot load video")
    setLessonDuration(null)
  }

  return (
    <>
      <ResponsiveAppBar/>
      <Button
        className="backButton"
        onClick={handleGoBack}
        startIcon={<ArrowBackIcon />}
        variant="contained"
        color="primary"
      >
        {t("create_lesson_page.go_to_class")}
      </Button>

      <div className="class-form-container">
        <h2 className="form-header">{t("create_lesson_page.title")}</h2>
        <form className="class-form" onSubmit={handleSubmit}>
          <input
            className="input-field"
            type="text"
            placeholder={t("create_lesson_page.lesson_name_placeholder")}
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
          />
          <input
            className="input-field"
            type="text"
            placeholder={t("create_lesson_page.video_link_placeholder")}
            value={videoData ? "" : videoLink}
            onChange={(e) => {
                setVideoLink(e.target.value)
                setVideoData(null)
              }
            }
          />
          <VideoUpload videoReadyCallback={(value) => { 
            setVideoLink(value.preview)
            setVideoData(value)
          }}/>
          <br/>
          <label className="checkbox-container">
            {t("create_lesson_page.public_lesson_prefix")} ({isPublicLesson ? t("create_lesson_page.public_lesson_description") : t("create_lesson_page.private_lesson_description")})

            <input
              className="checkbox"
              type="checkbox"
              checked={isPublicLesson}
              onChange={() => setIsPublicLesson(!isPublicLesson)}
            />
            <span className="checkmark"></span>
          </label>
          <button className="submit-btn" type="submit">{t("create_lesson_page.create_lesson_button")}</button>
        </form>
        <br/>
        <Divider component="div" >
          {lessonError && 
          <>
            {t("create_lesson_page.creating_lesson_error")}
            <br/>
            {lessonError}
          </>
          }

          {/* Show uploading status */}
          {uploading && <p>{t("create_lesson_page.uploading")}</p>}

        </Divider>
        <br/>
        <div
          style={{
            position: 'relative',
            width: "100%",         // Set the width based on the given width prop
            paddingTop: '56.25%', // Maintain 16:9 aspect ratio (56.25% is 9/16)
            margin: '0 auto',     // Center the container horizontally
            display: videoLoaded ? 'block' : 'none',
          }}
        >
          <ReactPlayer
            id="previewVideo"
            url={videoLink}
            ref={setRef}
            controls={true}
            onReady={handleReady}
            onError={handleError}
            onDuration={setLessonDuration}
            style={{ 
              position: 'absolute', 
              top: 0, 
              left: 0, 
              maxWidth: '100%', 
              maxHeight: '100%' 
            }}
          />
        </div>
      </div>
    </>
  )
}
