import React, {useState, useEffect} from 'react';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import './ClassForm.css'; // Import CSS file
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";
import '../components/backButton.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';


export default function CreateClass() {

  const [className, setClassName] = useState('');
  const [description, setDescription] = useState('');
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [classError, setClassError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)
      } else {

      }
    })
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Creating a class")
    fetch(process.env.REACT_APP_API_PATH + "/api/class", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': userEmail,
            'userid': userToken,

        },
        body: JSON.stringify({
            className: className,
            description: description,
            users: users,
            admins:admins,
        })
    }).then((response) => {
      if (!response.ok) {
        setClassError(true)
      } else {
        setClassName('');
        setDescription('');
        setUsers([]);
        setAdmins([]);
        navigate("/classes/");
      }
    }).catch(rejected => {
      console.log(rejected);
    });
  };

  const handleGoBack = () => {
    navigate("..")
  };

  return (
    <>
      <ResponsiveAppBar/>
      <Button
        className="backButton"
        onClick={handleGoBack}
        startIcon={<ArrowBackIcon />}
        variant="contained"
        color="primary"
      >
        {t("create_class_page.go_to_classes")}
      </Button>
      <div className="class-form-container">
        <h2 className="form-header">{t("create_class_page.title")}</h2>
        <form className="class-form" onSubmit={handleSubmit}>
          <input
            className="input-field"
            type="text"
            placeholder={t("create_class_page.class_name_placeholder")}
            value={className}
            onChange={(e) => setClassName(e.target.value)}
          />
          <textarea
            className="input-field"
            placeholder={t("create_class_page.description_placeholder")}
            rows="4"
            cols="50"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {/* User and Admin selection inputs */}
          <button className="submit-btn" type="submit">{t("create_class_page.create_class_button")}</button>
        </form>
        <br/>
        <Divider component="div" >
          {classError && t("create_class_page.creating_class_error")}
        </Divider>
      </div>
    </>
  );
}
