import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import '../components/backButton.css';

import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CommentIcon from '@mui/icons-material/Comment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { renderProgress } from '@mui/x-data-grid-generator/renderer/renderProgress';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

function toHHMMSS(number) {
    var sec_num = parseInt(number, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}


const deleteDialogStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function LessonStats() {
  const [seenUsers, setSeenUsers] = React.useState({});
  const [notSeenUsers, setNotSeenUsers] = React.useState([]);

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [lessonName, setLessonName] = useState(null);
  const [lessonID, setLessonID] = useState('');
  const [videoLink, setVideoLink] = useState(null);
  const [isPublicLesson, setIsPublicLesson] = useState(false);
  const [statusChangeOngoing, setStatusChangeOngoing] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)



  useEffect(() => {
    auth.authStateReady().then(() => {
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)

        fetch(process.env.REACT_APP_API_PATH + "/api/class/" + 
               params.class_id + 
               "/lesson/" + 
               params.lesson_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'username': user.email,
                'userid': user.accessToken,
            }
            // TODO: better
        }).then((res) => {
          if (res.status != 200) {
            console.log("Status was", res.status, "Error")
          } else if (res.status == 404) {
            console.log("No such lesson")
          } else {
            res.json().then((res) => {
              console.log("got lesson stat", res, "from server")
              setLessonName(res['LessonName'])
              setLessonID(res['LessonID'])
              setVideoLink(res['VideoLink'])
              setIsPublicLesson(res['Public'])
              const duration = res['Duration']

              const tempList = [];
              const tempNotSeenList = [];
              let counter = 1;
              for (let [key, value] of Object.entries(res['ProgressMap'])) {
                if (value === 0) {
                  tempNotSeenList.push(atob(key))
                } else {
                  let v = Math.floor(value)
                  tempList.push({id: counter, username: atob(key), percentage: (v / duration), time: toHHMMSS(v)})
                }
                counter += 1;
              }

              setSeenUsers(tempList)
              setNotSeenUsers(tempNotSeenList)
            })
          }
        }).catch(rejected => {
          console.log(rejected);
        });
        
      } else {
      }
    })
  }, [])

  function togglePublicPrivateLesson() {
    setStatusChangeOngoing(true)
    fetch(process.env.REACT_APP_API_PATH + "/api/class/" + 
           params.class_id + 
           "/lesson/" + 
           params.lesson_id, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': userEmail,
            'userid': userToken,
        }
    }).then((res) => {
      if (res.status == 200) {
        console.log("Lesson changed status successfuly", res)
        setIsPublicLesson(!isPublicLesson)
      } else {
        console.log("Status was", res.status, "Error")
      }
      setStatusChangeOngoing(false)
    }).catch(rejected => {
      console.log(rejected);
      setStatusChangeOngoing(false)
    });
  }

  function deleteLesson() {
    fetch(process.env.REACT_APP_API_PATH + "/api/class/" + 
           params.class_id + 
           "/lesson/" + 
           params.lesson_id, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': userEmail,
            'userid': userToken,
        }
    }).then((res) => {
      if (res.status == 200) {
        console.log("Lesson deleted successfuly", res)
        handleGoBack()
      } else {
        console.log("Status was", res.status, "Error")
      }
    }).catch(rejected => {
      console.log(rejected);
    });
  }

  const handleClearProgress = async () => {
    let usernames = seenUsers.filter((dataItem) => {
      return rowSelectionModel.includes(dataItem.id)
    })
    usernames = usernames.map((dataItem) => dataItem.username)

    for (let username of usernames) {
      try {
        const response = await fetch(process.env.REACT_APP_API_PATH + "/api/class/" + params.class_id + "/lesson/" + 
                  params.lesson_id + "/users/" + username, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'username': userEmail,
                'userid': userToken,
            },
            body: JSON.stringify({
                progress: 0
            })
        })
        if (!response.ok) {
            console.log("Error reseting lesson progress")
        }
      } catch (rejected) {
        console.log(rejected);
      }
    }
    window.location.reload();
  }

  const handleGoBack = () => {
    navigate("..")
  };

  const columns: GridColDef[] = [
    { field: 'username', headerName: t("lesson_stats_page.data_grid.username_col"),  flex: 1 },
    { field: 'time', headerName: t("lesson_stats_page.data_grid.time_watched_col"), flex: 1 },
    { field: 'percentage', type: "number", headerName: t("lesson_stats_page.data_grid.percentage_watched_col"), flex: 1, renderCell: renderProgress}
  ];

  return (
    <>
      <ResponsiveAppBar/>
        <Button
          className="backButton"
          onClick={handleGoBack}
          startIcon={<ArrowBackIcon />}
          variant="contained"
          color="primary"
        >
          {t("lesson_stats_page.go_to_class_button")}
        </Button>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="up"
        sx={{ minHeight: '100vh' }}
      >
        <div className="header-container">
          { lessonName ?
            <h1 className="header-title">{t("lesson_stats_page.title_prefix")} "{lessonName}"</h1>
            :
            <h1 className="header-title">{t("lesson_stats_page.lesson_not_found")}</h1>
          }
        </div>

        { lessonName &&
          <>
            <div style={{gap: 10, display: "flex"}}>
            <Button variant="contained" 
                    onClick={() => {navigator.clipboard.writeText("https://verivid.app/watch_lesson/" + params.class_id + 
                                               "/" + 
                                               params.lesson_id)}}>
                    {t("lesson_stats_page.copy_link_button")}
            </Button>

            <Button variant="contained" 
                    onClick={() => {navigator.clipboard.writeText(params.class_id + 
                                               "/" + 
                                               params.lesson_id)}}>
                    {t("lesson_stats_page.copy_id_button")}
            </Button>
            </div>

          <h1>
            {t("lesson_stats_page.seen_title")}
          </h1>

          <div>
            <DataGrid 
              sx={{
                width: '100%', // Make sure it takes up the full width of its container
                '& .MuiDataGrid-cell': {
                  padding: '8px', // Reduce padding for better fit on small screens
                },
                '@media (max-width: 600px)': { // Apply styling for small screens
                  fontSize: '0.8rem', // Reduce font size
                  '& .MuiDataGrid-columnHeaderTitle': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', // Avoid long text overflow in header
                  },
                  '& .MuiDataGrid-columnHeader': {
                    padding: '4px', // Reduce padding for column headers
                  },
                },
              }}
              checkboxSelection 
              disableRowSelectionOnClick 
              autoHeight 
              rows={seenUsers} 
              columns={columns}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              localeText={{
                noRowsLabel: t("lesson_stats_page.data_grid.no_rows")
              }}
            />
          </div>

          <br/>
          <Button variant="contained" 
                  onClick={handleClearProgress}
                  disabled={rowSelectionModel.length == 0}>
                 {t("lesson_stats_page.clear_progress")}
          </Button>
          <h1>
            {t("lesson_stats_page.unseen_title")}
          </h1>

          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {notSeenUsers.map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value}
                >
                  <ListItemText id={labelId} primary={value} />
                </ListItem>
              );
            })}
          </List>
          <Divider/>
          <br/>

          <Button
            onClick={togglePublicPrivateLesson}
            startIcon={isPublicLesson ? <VisibilityOffIcon/> : <VisibilityIcon/>}
            variant="contained"
            color="warning"
            disabled={statusChangeOngoing}
          >
            {isPublicLesson ? t("lesson_stats_page.set_lesson_private") : t("lesson_stats_page.set_lesson_public")}
          </Button>
          <br/>
          <Button
            onClick={() => {setDeleteDialog(true)}}
            startIcon={<DeleteIcon />}
            variant="contained"
            color="error"
          >
            {t("lesson_stats_page.delete_lesson")}
          </Button>

          <Modal
            open={deleteDialog}
            onClose={() => {setDeleteDialog(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={deleteDialogStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("lesson_stats_page.delete_popup.delete_lesson")} "{lessonName}"?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {t("lesson_stats_page.delete_popup.non_reversible")}
              </Typography>

          <Button
            onClick={() => {
              setDeleteDialog(false)
              deleteLesson()
          }}
            variant="contained"
            color="error"
          >
            {t("lesson_stats_page.delete_popup.yes")}
          </Button>

          <Button
            onClick={() => {setDeleteDialog(false)}}
            variant="contained"
            color="primary"
            sx={{m: 1}}
          >
            {t("lesson_stats_page.delete_popup.no")}
          </Button>
            </Box>
          </Modal>
          </>
        }
      </Grid>
    </>
  )
}
