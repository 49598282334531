import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import './ClassForm.css';
import { useParams, useNavigate } from "react-router-dom";
import { auth } from '../firebase';

import '../components/backButton.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';


export default function ClassSettings() {
  const params = useParams();
  const [userName, setUserName] = useState('');
  const [adminName, setAdminName] = useState('');

  const [userList, setUserList] = useState([])
  const [adminList, setAdminList] = useState([])
  const [isAdmin, setIsAdmin] = useState(false);
  const [addResponse, setAddResponse] = useState('');

  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    auth.authStateReady().then(() => {
      // Do whatever you want here ...
      var user = auth.currentUser;
      if (user !== null) {
        setUserEmail(user.email)
        setUserToken(user.accessToken)
        fetch(process.env.REACT_APP_API_PATH + "/api/class/" + params.class_id , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'username': user.email,
                'userid': user.accessToken,
            }
            // TODO: better
        }).then((res) => {
          if (res.status != 200) {
            console.log("Status was", res.status, "Error")
          } else {
            res.json().then((res) => {
              console.log("got class data", res, "from server")
              setUserList(res.UserList)
              setAdminList(res.AdminList)
              if (res['AdminList'].includes(user.email)) {
                setIsAdmin(true)
              }
            })
          }
        }).catch(rejected => {
          console.log(rejected);
        });
      } else {
        console.log("User isn't logged in")
      }
    })
  }, [])

  const handleSubmitAddUser = (e) => {
    e.preventDefault();
    handleSubmitAddEntity("/users")
  };

  const handleSubmitAddAdmin = (e) => {
    e.preventDefault();
    handleSubmitAddEntity("/admins")
  }

  const handleSubmitAddEntity = (path) => {
    
    fetch(process.env.REACT_APP_API_PATH + "/api/class/" + params.class_id + path, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'username': userEmail,
            'userid': userToken,
        },
        body: JSON.stringify({
            username: userName
        })
    }).then((response) => {
      console.log(response)
      if (response.ok) {
        setAddResponse("Success")
        setUserName('');
        setAdminName('');
      } else {
        return response.json()
      }
    }).then((respJson) => {
      console.log(respJson)
      setAddResponse(respJson.error)
    }).catch(rejected => {
      // TODO more indicative error
      console.log(rejected);
    });
  };

  const handleGoBack = () => {
    navigate("..")
  };

  return (
    <>
      <ResponsiveAppBar/>
      <Button
        className="backButton"
        onClick={handleGoBack}
        startIcon={<ArrowBackIcon />}
        variant="contained"
        color="primary"
      >
        {t("class_settings_page.go_to_class_button")}
      </Button>

      <div className="class-form-container">
        <h2 className="form-header">{t("class_settings_page.title")}</h2>
        <h4>{t("class_settings_page.add_user")}</h4>
        <form className="class-form" onSubmit={handleSubmitAddUser}>
          <input
            className="input-field"
            type="text"
            placeholder={t("class_settings_page.add_user_placeholder")}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />

          <button className="submit-btn" type="submit">{t("class_settings_page.add_user_button")}</button>
        </form>
        <h4>{t("class_settings_page.add_admin")}</h4>
        <form className="class-form" onSubmit={handleSubmitAddAdmin}>
          <input
            className="input-field"
            type="text"
            placeholder={t("class_settings_page.add_admin_placeholder")}
            value={adminName}
            onChange={(e) => setAdminName(e.target.value)}
          />

          <button className="submit-btn" type="submit">{t("class_settings_page.add_admin_button")}</button>
        </form>
        <br/>
        <Divider component="div" >
          {addResponse}
        </Divider>
        <h4>{t("class_settings_page.admins_list_title")}</h4>
        {
          adminList
        }
        <h4>{t("class_settings_page.users_list_title")}</h4>
        {
          userList
        }

      </div>

    </>
  )
}
