import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './i18n';
import './App.css';
import Home from './page/Home.js';
import Signup from './page/Signup.js';
import Login from './page/Login.js';
import AboutPage from './page/AboutPage.js';
import Profile from './page/Profile.js';
import Classes from './page/Classes.js';
import CreateClass from './page/CreateClass.js';
import Lessons from './page/Lessons.js';
import CreateLesson from './page/CreateLesson.js';
import LessonStats from './page/LessonStats.js';
import WatchLesson from './page/WatchLesson.js';
import PickLesson from './page/PickLesson.js';
import PaymentForm from './page/PaymentForm.js';
import ClassSettings from './page/ClassSettings.js';
import ContactUsPage from './page/ContactUsPage.js';
import ConfirmationPage from './page/ConfirmationPage.js';

import Footer from './components/Footer.js';
import VideoUpload from './components/VideoUpload.js';

function App() {
 
  return (
    <Router>
      <div>
        <Helmet>
          <style>{'body { background-color: #A8D9FF; padding-bottom: 100px;}'}</style>
        </Helmet>
        <section>                              
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/logout" element={<Home/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/subscribe" element={<PaymentForm/>}/>
            <Route path="/confirmation_page" element={<ConfirmationPage/>}/>
            <Route path="/contact_us" element={<ContactUsPage/>}/>

            <Route path="/classes/*">
              <Route index element={<Classes/>} />
              <Route path="new" element={<CreateClass/>} />
              <Route path=":class_id">
                <Route index element={<Lessons/>} />
                <Route path="new" element={<CreateLesson/>} />
                <Route path="settings" element={<ClassSettings/>} />
                <Route path=":lesson_id" element={<LessonStats />}/>
              </Route>
            </Route>
            
            <Route path="/watch_lesson/*" >
              <Route index element={<PickLesson />} />
              <Route path=":class_id/:lesson_id" element={<WatchLesson />}/>
              <Route path="*" element={<Navigate replace to="/watch_lesson"/>}/>
            </Route>

            <Route path="*" element={<Navigate replace to="/"/>}/>
          </Routes>                    
        </section>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
